// src/templates/page.js
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Img from "gatsby-image"
import AtvImg from "../components/atvImg"

import "../styles/style.scss"
import typographyStyles from "../styles/typography.module.scss"
import elementStyles from "../styles/elements.module.scss"
import homeStyles from "../styles/home.module.scss"

const IndexTemplate = ({ data }) => (
  <Layout
    hideHeader={true}
    hideFooter={true}
    >
    <SEO
      title={data.wpgraphql.pageBy.seo.title}
      description={data.wpgraphql.pageBy.excerpt}
    />
    <div className="home-content">
      <div
        className="home-intro"
        style={{
          padding: `80px 0`
        }}>
        <div
          className={elementStyles.container}
          style={{
            display: `flex`,
            alignItems: `flex-end`,
            justifyContent: 'space-between'
          }}
        >
          <div
            className="home-intro-content"
            style={{
              flexBasis: `580px`,
              flexGrow: `0`
            }}>
            <h1
              style={{
                margin: `0`
              }}>
              <span
                className="pre-heading"
                style={{
                  fontSize: `2rem`,
                  display: `block`,
                  color: `#2A89FA`,
                  marginBottom: `.25em`
                }}>
                  {data.wpgraphql.pageBy.home_content.preHeading}
                </span>
              <span className="main-heading">{data.wpgraphql.pageBy.home_content.mainHeading}</span>
            </h1>
            <div
              className="button-row"
              style={{
                marginTop: `2rem`
              }}>
              {data.wpgraphql.pageBy.home_content.pageLinks.map((pageLink,i) => (
                <Link
                  to={`/${pageLink.pageLink.uri}/`}
                  key={'pagelink_' + i}
                  className="button"
                  style={{
                    fontWeight: `800`,
                    color: `#2A89FA`,
                    textDecoration: `none`,
                    letterSpacing: `.025em`
                  }}>
                  {pageLink.buttonText}
                  <i
                    className="fal fa-long-arrow-right"
                    style={{
                      display: `inline-block`,
                      verticalAlign: `middle`,
                      marginLeft: `.5em`,
                      fontSize: `1.4em`
                    }}></i>
                </Link>
              ))}
            </div>
          </div>
          <div
            className="home-intro-logo"
            style={{
              flexBasis: `293px`,
              flexGrow: `0`,

            }}>
            <Img
              fluid={data.wpgraphql.pageBy.home_content.logo.imageFile.childImageSharp.fluid}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="home-projects">
        <div
          className={elementStyles.container}
        >
          <div
            className={`projects-slider ${homeStyles.projectsSlider}`}
            >
            {data.wpgraphql.pageBy.home_content.projects.map((project,i) => (
              <div
                className={`project-tile ${homeStyles.projectTile}`}
                key={'project_' + i}>
                <Link
                  to={`/projects/${project.uri}/`}
                  className={`project-link ${homeStyles.projectLink}`}>
                  <AtvImg
                    layers={[
                      `${project.projectThumbnail.thumbnail.base.imageFile.childImageSharp.sizes.src}`,
                      `${project.projectThumbnail.thumbnail.bottom.imageFile.childImageSharp.sizes.src}`,
                      //`${project.projectThumbnail.thumbnail.middle.imageFile.childImageSharp.sizes.src}`,
                      `${project.projectThumbnail.thumbnail.top.imageFile.childImageSharp.sizes.src}`,
                    ]}
                    isStatic={false}
                    staticFallback={project.featuredImage.imageFile.childImageSharp.sizes.src}
                    className={'atvImg'}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
export default IndexTemplate
export const query = graphql`
  {
    wpgraphql {
      pageBy(uri: "home") {
        title
        excerpt
        home_content {
          preHeading
          mainHeading
          logo {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(quality: 60, maxWidth: 293) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          pageLinks {
            pageLink {
              ... on WPGraphQL_Page {
                uri
              }
            }
            buttonText
          }
          projects {
            ... on WPGraphQL_Project {
              title
              uri
              featuredImage {
                sourceUrl
                imageFile {
                  childImageSharp {
                    sizes(quality: 60) {
                      src
                    }
                  }
                }
              }
              projectThumbnail {
                thumbnail {
                  base {
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        sizes(quality: 60) {
                          src
                        }
                      }
                    }
                  }
                  bottom {
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        sizes(quality: 60) {
                          src
                        }
                      }
                    }
                  }
                  middle {
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        sizes(quality: 60) {
                          src
                        }
                      }
                    }
                  }
                  top {
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        sizes(quality: 60) {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seo {
          title
        }
      }
    }
  }
`
